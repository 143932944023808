import { useCallback, useEffect, useState } from 'react'

export const useContainerDimensions = ref => {
  const getDimensions = useCallback(() => ({
    width: ref.current?ref.current.offsetWidth:0,
    height: ref.current?ref.current?.offsetHeight:0,
  }), [ref])
  
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  
  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }
  
    if (ref.current) {
      setDimensions(getDimensions())
    }
  
    window.addEventListener('resize', handleResize)
  
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [getDimensions, ref])
  
  return dimensions
}