import React from 'react'
import { DomParser } from './domParser'
import styled from 'styled-components'
import { StyledImage } from '../../components/video/StyledVideo'

const StyledFigure = styled.figure`
  margin: 0;

  &:last-child {
    ${StyledImage} {
      margin-bottom: 0;
    }
  }
`

export const figure: DomParser = (el, props) => <StyledFigure { ...props } />