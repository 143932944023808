import BaseLink, { LinkProps as BaseLinkProps } from 'next/link'
import React, { ComponentProps } from 'react'

export type LinkProps = BaseLinkProps & Pick<ComponentProps<'a'>, 'target' | 'onClick'>

export const Link: React.FC<LinkProps> = ({ href, children, target, ...linkProps }) => {
  return href && href !== '#' && !target ? (
    <BaseLink href={href} { ...linkProps }>
      {children}
    </BaseLink>
  ) : (
    <a href={typeof href === 'string' ? href : href.href as string} target={target} onClick={linkProps.onClick}>
      {children}
    </a>
  )
}