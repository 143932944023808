import React from 'react'

export type CircleIconProps = React.ComponentProps<'svg'>

export const CircleIcon: React.VFC = (props) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 35 35" xmlSpace="preserve" { ...props }>
    <g id="Group_3705" transform="translate(-1666 -735)">
      <path id="Ellipse_55" className="st0" d="M1683.5,736.5c-8.8,0-16,7.2-16,16c0,4.2,1.7,8.3,4.7,11.3c6.2,6.2,16.4,6.2,22.6,0 c6.2-6.2,6.2-16.4,0-22.6C1691.8,738.2,1687.7,736.5,1683.5,736.5 M1683.5,735c9.7,0,17.5,7.8,17.5,17.5s-7.8,17.5-17.5,17.5 s-17.5-7.8-17.5-17.5S1673.8,735,1683.5,735z"/>
      <path id="Path_6944" className="st0" d="M1687.1,753c0.2-0.2,0.2-0.4,0-0.6c0,0,0,0,0,0l-5.2-5.2c-0.2-0.2-0.4-0.2-0.6,0c0,0,0,0,0,0 l-0.7,0.7c-0.2,0.2-0.2,0.4,0,0.6c0,0,0,0,0,0l4.2,4.2l-4.2,4.2c-0.2,0.2-0.2,0.4,0,0.6c0,0,0,0,0,0l0.7,0.7c0.2,0.2,0.4,0.2,0.6,0 c0,0,0,0,0,0L1687.1,753z"/>
    </g>
  </svg>
)