import React from 'react'
import Grid from '@material-ui/core/Grid'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { CreditUnionIcon, IconCodeType } from '../icon/CreditUnionIcon'
import {
  StyledCardContainer,
  StyledTitleCheveron,
  StyledTitleText,
  StyledTitleTextContainer,
} from './styledNavDropdownUtilityBar'
import { Link, LinkProps } from '../link/link'

export type UtilityBarCardProps = React.ComponentProps<typeof StyledCardContainer> & Pick<LinkProps, 'href'> & {
  title: string
  iconCode: IconCodeType
  target: boolean
}

export const UtilityBarCard: React.FC<UtilityBarCardProps> = ({ title, iconCode, href, children, target, ...props }) => {
  return (
    <StyledCardContainer {...props}>
      <Link href={href} passHref target={target ? '_blank' :'_self'}>
        <Grid title={title} item justifyContent="center" alignItems="flex-start">
          <CreditUnionIcon height={39} width={39} iconCode={iconCode} />
        </Grid>
        <StyledTitleTextContainer>

          <StyledTitleText title={title}>
            {title}
          </StyledTitleText>
          <StyledTitleCheveron icon={faChevronRight} />
        </StyledTitleTextContainer>
      </Link>
    </StyledCardContainer>
  )
}