import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { isBrowser } from '@atomixdesign/rem-scaling'
import { PREVIEW_SCROLL_POSITION_KEY } from '../constants/previewScrollPosition'

export const useIsPreview = () => {
  const { query } = useRouter()

  return useMemo(() => {
    return typeof query.token === 'string'
  }, [query])
}

export const usePreviewScrollPosition = (): [number, (position: number) => void] => {
  const [position, setPosition] = useState(Number.parseInt(isBrowser() ? window.sessionStorage.getItem(PREVIEW_SCROLL_POSITION_KEY) ?? '0' : '0'))

  useEffect(() => {
    if (isBrowser()) {
      window.sessionStorage.setItem(PREVIEW_SCROLL_POSITION_KEY, String(position))
    }
  }, [position])

  return [position, setPosition]
}