import styled, { css } from 'styled-components'
import { Icon } from '../icon/icon'
import React from 'react'

export type StyledSearchIconContainerProps = {
  $secondary?: boolean
  $sticky?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const mobileStyles = css`
  width: ${props => props.theme.typography.pxToRem(40)};
  min-width: ${props => props.theme.typography.pxToRem(40)};
  height: ${props => props.theme.typography.pxToRem(40)};
  min-height: ${props => props.theme.typography.pxToRem(40)};
`

const stickyAndSecondaryStyles = css`
  width: ${props => props.theme.typography.pxToRem(52)};
  min-width: ${props => props.theme.typography.pxToRem(52)};
  height: ${props => props.theme.typography.pxToRem(52)};
  min-height: ${props => props.theme.typography.pxToRem(52)};
`

const primaryStyles = css`
  width: ${props => props.theme.typography.pxToRem(52)};
  height: ${props => props.theme.typography.pxToRem(52)};

  ${props => props.theme.breakpoints.up('md')} {
    width: ${props => props.theme.typography.pxToRem(67)};
    min-width: ${props => props.theme.typography.pxToRem(67)};
    height: ${props => props.theme.typography.pxToRem(67)};
    min-height: ${props => props.theme.typography.pxToRem(67)};
  }
`

export const StyledSearchIconContainer = styled.button<StyledSearchIconContainerProps>`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f2f2f2;
  transition: all 0.2s ease;
  ${mobileStyles}

  &:hover {
    cursor: pointer;

    > svg {
      color: #f35a21;
    }
  }

  &:focus {
    outline: auto;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    ${props => (props.$secondary || props.$sticky) ? stickyAndSecondaryStyles : primaryStyles}
  }
`

export const StyledSearchIcon = styled(Icon)<{$focus?: boolean}>`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  color: ${props => props.theme.palette.primary.dark};
  transition: color 0.2s ease;
  fill: ${props => props.$focus && props.theme.palette.primary.main};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(20)};
  }
`