import { css } from 'styled-components'

export const hoverStylesBlack = css`
  .MuiButton-label {
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
  }

  &::before {
    content: " ";
    opacity: 0;
    height: 8px;
    width: 4px;
  }

  &::after {
    content: " ";
    background: url(/images/chevron_black.svg);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    padding-top: 1px;
    display: inline-block;
    height: 8px;
    width: 10px;
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
  }

  &:hover,
  &:focus {
    background: transparent;
    color: ${props=> props.theme.palette.text.primary};

    .MuiButton-label {
      transform: translateX(-4px);
    }

    &::after {
      opacity: 1;
      transform: translateX(4px);
    }
  }

  &:focus {
    outline: ${props => props.theme.typography.pxToRem(1)} auto -webkit-focus-ring-color;
  }
`
export const hoverStylesOrange = css`
  .MuiButton-label {
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
  }

  &::before {
    content: ">";
    opacity: 0;
    height: 8px;
    width: 4px;
  }

  &::after {
    content: " ";
    background: url(/images/chevron_orange.svg);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    padding-top: 1px;
    display: inline-block;
    height: 8px;
    width: 10px;
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
  }

  &:hover {
    background: transparent;
    color: ${props=> props.theme.palette.secondary.dark};

    .MuiButton-label {
      transform: translateX(-4px);
    }

    &::after {
      opacity: 1;
      transform: translateX(4px);
    }
  }
`
export const hoverStylesGreen = css`
  .MuiButton-label {
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
  }

  &::before {
    content: ">";
    opacity: 0;
    height: 8px;
    width: 4px;
  }

  &::after {
    content: " ";
    background: url(/images/chevron_green.svg);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    padding-top: 1px;
    display: inline-block;
    height: 8px;
    width: 10px;
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
  }

  &:hover {
    background: transparent;
    color: ${props=> props.theme.palette.primary.light};

    .MuiButton-label {
      transform: translateX(-4px);
    }

    &::after {
      opacity: 1;
      transform: translateX(4px);
    }
  }
`
export const hoverStylesCta = css`
  .MuiButton-label {
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
  }

  &::before {
    content: ">";
    opacity: 0;
    height: 8px;
    width: 4px;
  }

  &::after {
    content: " ";
    background: url(/images/chevron_white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    padding-top: 1px;
    display: inline-block;
    height: 8px;
    width: 10px;
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
  }

  &:hover {
    background: ${props => props.theme.palette.primary.main};
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    color: ${props => props.theme.palette.common.white};

    .MuiButton-label {
      transform: translateX(-4px);
    }

    &::after {
      opacity: 1;
      transform: translateX(4px);
    }
  }
`
export const hoverStylesDark = css`
  .MuiButton-label {
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
  }

  &::before {
    content: ">";
    opacity: 0;
    height: 8px;
    width: 4px;
  }

  &::after {
    content: " ";
    background: url(/images/chevron_white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    padding-top: 1px;
    display: inline-block;
    height: 8px;
    width: 10px;
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
  }

  &:hover {
    background: transparent;
    border: solid 2px ${props => props.theme.palette.secondary.main};
    color: ${props=> props.theme.palette.common.white};

    .MuiButton-label {
      transform: translateX(-4px);
    }

    &::after {
      opacity: 1;
      transform: translateX(4px);
    }
  }
`