import React, { ComponentProps, useState } from 'react'
import Image from 'next/image'
import { DomParser } from './domParser'
import getConfig from 'next/config'
import { ImageSizeQuery } from '../__generated__/fetchImageDimensions'

const ImageElement: React.FC<ComponentProps<'img'>> = (props) => {
  const [dimensions, setDimensions] = useState<ImageSizeQuery['asset']>({ width: props.width as number | undefined, height: props.height as number | undefined })
  const imageSrc = props.src as string
  const imageAlt = props.alt as string
  const { publicRuntimeConfig } = getConfig()
  const images = publicRuntimeConfig.images

  const domainMatch = images?.domains.some(domain => imageSrc.includes(domain))
  const id = props['data-image']

  if (domainMatch && typeof id === 'string') {
    if ((!dimensions?.width || !dimensions?.height)) {
      import('../fetchImageDimensions').then(mod => {
        mod.fetchImageDimensions(id).then((dimensions) => {
          if (typeof dimensions?.height === 'number' && typeof dimensions?.width === 'number') {
            setDimensions(dimensions)
          }
        })
      })
    }

    if (dimensions) {
      const { width, height } = dimensions

      return width && height ? (
        <Image src={imageSrc} alt={imageAlt} width={width} height={height} />
      ) : <></>
    } else {
      return <></>
    }
  }

  // eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element
  return <img { ...props } />
}

export const img: DomParser = (element, { children, ...props }) => <ImageElement { ...props } />