import React from 'react'
import parse, { HTMLReactParserOptions } from 'html-react-parser'
import { runParsers } from './parsers'

const options: HTMLReactParserOptions = {  
  replace: domNode => runParsers(domNode, options),
}

export const parseHtml = (html: string) => {
  return <>{parse(html, options)}</>
}

export const html = parseHtml