import React from 'react'
import Grid from '@material-ui/core/Grid'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { StyledCardList, StyledTitleChevron, StyledTitleText, StyledTitleLinksWrapper } from './styledNavDropdownLinkCard'
import { Link, LinkProps } from '../link/link'
import { isNotNull } from '../../utils/nonNull'

export type NavDropdownLinkCardProps =  React.ComponentProps<typeof Grid> & Pick<LinkProps, 'href'> & {
  title: string
  target: boolean
}

export const NavDropdownLinkCard: React.FC<NavDropdownLinkCardProps> = ({ title, href, children, target, ...props }) => {
  return (
    <>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" {...props}>
        <StyledTitleLinksWrapper item>
          {isNotNull(href) && href!=='' ? (<Link href={href} passHref target={target ? '_blank' :'_self'}>
            <StyledTitleText title={title}>
              { title }
              <StyledTitleChevron icon={faChevronRight}/>
            </StyledTitleText>
          </Link>) : ( <Link target={target ? '_blank' :'_self'} href={href} passHref><StyledTitleText title={title}>
            { title }
          </StyledTitleText>
          </Link>) }
        </StyledTitleLinksWrapper>
      </Grid>
      <Grid container>
        <StyledCardList>
          { children }
        </StyledCardList>
      </Grid>
    </>
  )
}
