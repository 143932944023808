import React, { useContext, useEffect, useState } from 'react'
import { StyledListContainer } from './styledMenuItemList'
import { StyledMenuNavigationContainer } from './styledMobileNavigation'
import { createPortal } from 'react-dom'
import { MobileMenuContext } from './MobileMenuContext'

export type MobileMenuNavigationProps = React.ComponentProps<typeof StyledMenuNavigationContainer> & {
  level: number
  idx: number
  isOpen?: boolean
  animationTime?: number
}

export const MobileMenuNavigation: React.FC<MobileMenuNavigationProps> = ({ children, level, idx, animationTime=300, ...props }) => {
  const menuContext = useContext(MobileMenuContext)
  const currentIndex = menuContext.getItemIndex(level, idx)
  const isGlobalOpen = menuContext.menuState[0] //level 0 nav
  const isOpen = menuContext.menuState[currentIndex]
  const portal = menuContext.portal
  const [shouldRender, setShouldRender] = useState(isOpen)

  useEffect(() => {
    if (isOpen) {
      setShouldRender(isOpen)
    } else {
      const animateTimeout = setTimeout(() => setShouldRender(isOpen), animationTime - 50)

      return () => {
        clearTimeout(animateTimeout)
      }
    }
  }, [isOpen, animationTime])

  if (!shouldRender) {
    return null
  }

  const navigationSlider = (
    <StyledMenuNavigationContainer $isGlobalOpen={isGlobalOpen} $isOpen={isOpen} $level={level} $animationTime={animationTime} {...props}>
      <StyledListContainer>
        { children }
      </StyledListContainer>
    </StyledMenuNavigationContainer>
  )

  return portal ? (
    createPortal(navigationSlider, portal)
  ) : (
    navigationSlider
  )
}
