import React from 'react'
import { DomParser } from './domParser'
import { AlertNotice } from '../../components/AlertNotice/alert'

export const alert: DomParser = (el, props) => {
  const type = props['data-alert'] as 'default' | 'light' | 'dark'

  return (
    <AlertNotice type={type === 'default' ? undefined : type} component={'p'}>
      {props.children}
    </AlertNotice>
  )
}

alert.match = (el, props) => Boolean(props['data-alert'])