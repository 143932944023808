import React, { useCallback, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Alert, AlertProps } from '@material-ui/lab'
import hash from 'object-hash'
import { Container } from '@material-ui/core'
import { Button } from '../button/button'

export const StyledAlert = styled(Alert)<{ $type?: AlertNoticeProps['type']; display: boolean }>`
  display: ${props => props.display ? 'flex' : 'none'};
  border-radius: unset;

  .MuiAlert-icon {
    color: #ed8b00;
    align-items: center;
  }

  .MuiAlert-root {
    padding: ${props => props.theme.typography.pxToRem(24)} ${props => props.theme.typography.pxToRem(29)} ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(32)};
    ${props => props.theme.breakpoints.up('sm')} {
      padding: ${props => props.theme.typography.pxToRem(35)} ${props => props.theme.typography.pxToRem(57)} ${props => props.theme.typography.pxToRem(35)} ${props => props.theme.typography.pxToRem(58)};
    }
  }

  .MuiAlert-message {
    font-size: ${props => props.theme.typography.pxToRem(15)};
    line-height: ${props => props.theme.typography.pxToRem(24)};
  }

  a {
    color: ${props => props.theme.palette.secondary.main};
  }

  a:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }

  background: none;
  ${props => props.$type === 'dark' && 'color: white;'}
`

const StyledContainer = styled(Container)<{$type?: AlertNoticeProps['type']}>`
  ${props => props.theme.breakpoints.down('sm') && 'padding: unset;'}

  ${props => props.$type === 'primary' && css`
  color: #1c1f2a;
  background-color: #f2f2f2;
  `}

  ${props => props.$type === 'light' && css`
  color: #1c1f2a;
  background-color: #fff7e6;
  `}

  ${props => props.$type === 'dark' && css`
  color: white;
  background-color: #10131d;
  `}
`

const StyledButton = styled(Button)<{$color?: string}>`
  font-size: 12px;
  color: white;

  &:hover {
    color: white;
  }
`

export type AlertNoticeProps = AlertProps & {
  type?: 'light' | 'dark' | 'primary'
  hashInfo: string
  buttonText?: string
};

export const GlobalAlert: React.FC<AlertNoticeProps> = ({ children, type, buttonText, hashInfo, ...props }) => {
  const [displayState, setDisplay] = useState(false)

  useEffect(() => {
    const hashedChild = hash(hashInfo)

    if (localStorage.getItem(hashedChild) !== 'hidden') {
      setDisplay(true)
    }
  }, [hashInfo])

  const setDisplayStorage = useCallback((unhashedChild) => {
    localStorage.setItem(hash(unhashedChild), 'hidden')
    setDisplay(false)
  }, [])

  return (
    <StyledContainer $type={type} maxWidth={false}>
      <StyledContainer maxWidth="lg">
        <StyledAlert display={displayState} onClose={() => {setDisplayStorage(hashInfo)}} severity="info" $type={type}   action={buttonText ? (
          <StyledButton onClick={()=> setDisplayStorage(hashInfo)} $color={type} primary buttonColor="orange">
            {buttonText}
          </StyledButton>) : null
        } { ...props }>
          {children}
        </StyledAlert>
      </StyledContainer>
    </StyledContainer>
  )
}
