import React, { ComponentProps } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from 'styled-components'
import LazyHydrate from 'react-lazy-hydration'

export type LazyComponentProps = Omit<ComponentProps<typeof LazyHydrate>, 'whenVisible' | 'whenIdle'> & {
  //
}

export const LazyComponent: React.FC<LazyComponentProps> = ({ children, ...props }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <LazyHydrate whenVisible={!isDesktop} whenIdle={isDesktop} { ...props }>
      {children}
    </LazyHydrate>
  )
}