import styled from 'styled-components'

export const StyledListContainer = styled.ul`
  width: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  height: 90vh;
  max-height: 200vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  li {
    margin-bottom: 0;

    &::before {
      display: none;
    }
  }

  & > a {
    text-decoration: none;
  }
`