import React, { ComponentProps } from 'react'

import { IconComponent, SvgIconComponent, ImgIconComponent } from './CreditUnionIcon/iconComponentType'
import dynamic from 'next/dynamic'
import analytics from './CreditUnionIcon/icons/analytics.svg'
import calculator from './CreditUnionIcon/icons/calculator.svg'
import brokenBar from './CreditUnionIcon/icons/broken-bar.svg'
import money from './CreditUnionIcon/icons/money.svg'
import piggy from './CreditUnionIcon/icons/piggy.svg'
import umbrella from './CreditUnionIcon/icons/umbrella.svg'
import wallet from './CreditUnionIcon/icons/wallet.svg'
import contact from './CreditUnionIcon/icons/contact.svg'
import chat from './CreditUnionIcon/icons/chat.svg'
import findus from './CreditUnionIcon/icons/findus.svg'
import mobilelending from './CreditUnionIcon/icons/mobilelending.svg'
import nofee from './CreditUnionIcon/icons/nofee.svg'
import clock from './CreditUnionIcon/icons/clock.svg'
import visacard from './CreditUnionIcon/icons/visacard.svg'
import caravan from './CreditUnionIcon/icons/caravan.svg'
import calendar from './CreditUnionIcon/icons/calendar.svg'
import car from './CreditUnionIcon/icons/car.svg'
import home from './CreditUnionIcon/icons/home.svg'
import monitor from './CreditUnionIcon/icons/monitor.svg'
import invest from './CreditUnionIcon/icons/invest.svg'
import ship from './CreditUnionIcon/icons/ship.svg'
import circlestar from './CreditUnionIcon/icons/circlestar.svg'

//Mapping of iconCode to Icon Function Component
type CreditUnionIconMapType = Record<string, {
  svg: React.ComponentType<ComponentProps<SvgIconComponent>>
  img?: typeof analytics
}>

export const CreditUnionIconMap: CreditUnionIconMapType = {
  'analytics': {
    svg: dynamic(() => import('./CreditUnionIcon/AnalyticsIcon').then(mod => mod.AnalyticsIcon)),
    img: analytics,
  },
  'hamburger': {
    svg: dynamic(() => import('./CreditUnionIcon/BrokenBarIcon').then(mod => mod.BrokenBarIcon)),
    img: brokenBar,
  },
  'calculator': {
    svg: dynamic(() => import('./CreditUnionIcon/CalculatorIcon').then(mod => mod.CalculatorIcon)),
    img: calculator,
  },
  'money': {
    svg: dynamic(() => import('./CreditUnionIcon/MoneyIcon').then(mod => mod.MoneyIcon)),
    img: money,
  },
  'piggy': {
    svg: dynamic(() => import('./CreditUnionIcon/PiggyIcon').then(mod => mod.PiggyIcon)),
    img: piggy,
  },
  'umbrella': {
    svg: dynamic(() => import('./CreditUnionIcon/UmbrellaIcon').then(mod => mod.UmbrellaIcon)),
    img: umbrella,
  },
  'wallet': {
    svg: dynamic(() => import('./CreditUnionIcon/WalletIcon').then(mod => mod.WalletIcon)),
    img: wallet,
  },
  'contact': {
    svg: dynamic(() => import('./CreditUnionIcon/ContactUsIcon').then(mod => mod.ContactUsIcon)),
    img: contact,
  },
  'chat': {
    svg: dynamic(() => import('./CreditUnionIcon/ChatToUsIcon').then(mod => mod.ChatToUsIcon)),
    img: chat,
  },
  'findus': {
    svg: dynamic(() => import('./CreditUnionIcon/FindUsIcon').then(mod => mod.FindUsIcon)),
    img: findus,
  },
  'mobilelending': {
    svg: dynamic(() => import('./CreditUnionIcon/MobileLendingIcon').then(mod => mod.MobileLendingIcon)),
    img: mobilelending,
  },
  'times': {
    svg: dynamic(() => import('./CreditUnionIcon/TimesIcon').then(mod => mod.TimesIcon)),
  },
  'freecharge': {
    svg: dynamic(() => import('./CreditUnionIcon/FreeChargeIcon').then(mod => mod.FreeChargeIcon)),
    img: nofee,
  },
  'visacard': {
    svg: dynamic(() => import('./CreditUnionIcon/VisaDebitCardIcon').then(mod => mod.VisaDebitCardIcon)),
    img: visacard,
  },
  'clock': {
    svg: dynamic(() => import('./CreditUnionIcon/ClockIcon').then(mod => mod.ClockIcon)),
    img: clock,
  },
  'double-traingle': {
    svg: dynamic(() => import('./CreditUnionIcon/DoubleTriangle').then(mod => mod.DoubleTriangle)),
  },
  'traingle': {
    svg: dynamic(() => import('./CreditUnionIcon/Triangle').then(mod => mod.Triangle)),
  },
  'caravan': {
    svg: dynamic(() => import('./CreditUnionIcon/Caravan').then(mod => mod.Caravan)),
    img: caravan,
  },
  'calendar': {
    svg: dynamic(() => import('./CreditUnionIcon/Calendar').then(mod => mod.Calendar)),
    img: calendar,
  },
  'car': {
    svg: dynamic(() => import('./CreditUnionIcon/Car').then(mod => mod.Car)),
    img: car,
  },
  'home': {
    svg: dynamic(() => import('./CreditUnionIcon/Home').then(mod => mod.Home)),
    img: home,
  },
  'monitor': {
    svg: dynamic(() => import('./CreditUnionIcon/Monitor').then(mod => mod.Monitor)),
    img: monitor,
  },
  'invest': {
    svg: dynamic(() => import('./CreditUnionIcon/Invest').then(mod => mod.Invest)),
    img: invest,
  },
  'ship': {
    svg: dynamic(() => import('./CreditUnionIcon/Ship').then(mod => mod.Ship)),
    img: ship,
  },
  'circlestar': {
    svg: dynamic(() => import('./CreditUnionIcon/CircleStar').then(mod => mod.CircleStar)),
    img: circlestar,
  },
} as const

export type IconCodeType = keyof typeof CreditUnionIconMap

export type CreditUnionIconProps = React.ComponentProps<IconComponent> & {
  iconCode: IconCodeType
}

export const CreditUnionIcon: React.FC<CreditUnionIconProps> = ({ iconCode, svg, ...props }) => {
  const Icon = CreditUnionIconMap[iconCode]

  if (Icon?.svg && (!Icon?.img || svg)) {
    return <Icon.svg { ...props as ComponentProps<typeof Icon.svg> } />
  }

  // eslint-disable-next-line @next/next/no-img-element
  return Icon?.img ? <img src={Icon.img} alt={''} { ...props as ComponentProps<ImgIconComponent> } /> : null
}
